import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Pencil, Trash, Eye, MessageCircleMore } from 'lucide-react';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { useToast } from '@/components/ui/use-toast';

type Status = 'Completed' | 'InProgress';

interface TableData {
  id: number;
  status: Status;
  projectName: string;
  requirementsCount: number;
  created: string;
}

const initialData: TableData[] = [
  {
    id: 1,
    status: 'Completed',
    projectName: 'Tender - 1',
    requirementsCount: 87,
    created: '30-05-2024',
  },
  {
    id: 2,
    status: 'Completed',
    projectName: 'Tender - 2',
    requirementsCount: 74,
    created: '28-04-2024',
  },
  {
    id: 3,
    status: 'InProgress',
    projectName: 'Tender - 3',
    requirementsCount: 65,
    created: '10-06-2024',
  },
  {
    id: 4,
    status: 'InProgress',
    projectName: 'Tender - 4',
    requirementsCount: 89,
    created: '19-07-2024',
  },
  {
    id: 5,
    status: 'InProgress',
    projectName: 'Tender - 5',
    requirementsCount: 56,
    created: '12-08-2024',
  },
];

export const DataTable: React.FC = () => {
  const [tableData, setTableData] = useState<TableData[]>(initialData);
  const { toast } = useToast();

  const handleDelete = (id: number) => {
    setTableData((prevData) => prevData.filter((item) => item.id !== id));
    toast({
      title: "Project Deleted",
      description: "The Project has been deleted successfully.",
      className: "bg-green-600 text-white",
      duration: 3000,
    });
  };

  return (
    <Card className="shadow-md">
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/5 text-purple-600">
                <div className="flex items-center">
                  Project
                  <Button variant="ghost" size="sm" className="ml-2 hover:bg-transparent p-0">
                    <span className="sr-only">Sort by Project</span>
                  </Button>
                </div>
              </TableHead>
              <TableHead className="w-1/5 text-purple-600">
                <div className="flex items-center h-full">Status</div>
              </TableHead>
              <TableHead className="w-1/5 text-purple-600">Requirements</TableHead>
              <TableHead className="w-1/5 text-purple-600">Created Date</TableHead>
              <TableHead className="w-[150px] text-purple-600">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.projectName}</TableCell>
                <TableCell className="font-medium">{row.status}</TableCell>
                <TableCell>{row.requirementsCount}</TableCell>
                <TableCell>{row.created}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            aria-label="View/Edit Tender Documents"
                            onClick={() => window.open(`/tender-documents/${row.id}`, '_blank')}
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>View/Edit Tender Documents</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            variant="ghost"
                            size="sm"
                            aria-label="See Classification Results"
                            onClick={() =>
                              window.open(`/classification-results/${row.id}`, '_blank')
                            }
                          >
                            <Eye className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>See Classification Results</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button variant="ghost" size="sm" aria-label="Chat with RFPs">
                            <MessageCircleMore className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Chat with RFPs</p>
                        </TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button 
                            variant="ghost" 
                            size="sm" 
                            aria-label="Delete Project"
                            onClick={() => handleDelete(row.id)}
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Delete Project</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
