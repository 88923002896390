import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Upload, X, LoaderCircle } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { uploadDocument } from '@/services/api';
import { useToast } from '@/components/ui/use-toast';
import { RootState } from '@/redux/store';

export const UploadSection: React.FC = () => {
  const { toast } = useToast();
  const organizationInfo = useSelector((state: RootState) => state.upload.organizationInfo);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const isOrganizationInfoComplete = () => {
    return (
      organizationInfo.orgName !== '' &&
      organizationInfo.email !== '' &&
      organizationInfo.projectName !== ''
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024 && file.type === 'application/pdf') {
        setSelectedFile(file);
        setError(null);
      } else {
        setError('Please select a valid PDF file (max 5MB).');
      }
    }
  };

  const handleUpload = async () => {
    if (!isOrganizationInfoComplete()) {
      toast({
        title: 'Profile Information Required',
        description:
          'Please save Organization Name, Email, and Project Name first to proceed with the upload.',
        variant: 'destructive',
        duration: 5000,
        className: 'bg-red-500 text-white',
      });
      return;
    }
    if (!selectedFile) {
      setError('Please select a file to upload.');
      return;
    }
    setUploading(true);
    setElapsedTime(0);
    const interval = setInterval(() => setElapsedTime((time) => time + 1), 1000);
    intervalRef.current = interval;

    try {
      await uploadDocument(selectedFile, {
        orgName: organizationInfo.orgName,
        email: organizationInfo.email,
        projectName: organizationInfo.projectName
      });
      console.log('Upload and download successful');
      clearSelectedFile();
      toast({
        title: 'Upload Successful',
        description: 'Your file has been uploaded and processed successfully.',
        className: 'bg-green-600 text-white',
        duration: 3000,
      });
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Error uploading file. Please try again.');
      toast({
        title: 'Upload Failed',
        description: 'There was an error uploading your file. Please try again.',
        className: 'bg-red-600 text-white',
        duration: 3000,
      });
    } finally {
      setUploading(false);
      clearInterval(interval);
    }
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
    setError(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <Card className="w-full shadow-md bg-white h-full flex flex-col">
      <CardContent className="p-6 flex flex-col flex-grow relative">
        {uploading && (
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-50 rounded-lg">
            <LoaderCircle className="h-12 w-12 animate-spin text-white mb-4" />
            <p className="text-lg font-medium text-white text-center">
              Please wait, text extraction and requirement classification is in progress... <br />
              Elapsed time: {elapsedTime} seconds <br />
              You will see output.zip file downloaded in your machine (Downloads folder) once this
              request completes.
            </p>
          </div>
        )}
        <h2 className="text-xl font-medium text-center text-gray-700 mb-4">
          Add a new RFP to extract requirements
        </h2>
        <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-8 text-center flex-grow flex flex-col justify-center">
          <input
            type="file"
            accept=".pdf"
            className="hidden"
            id="file-upload"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <Button
            onClick={handleFileSelect}
            variant="ghost"
            className="mx-auto mb-4"
          >
            <Upload className="h-12 w-12 text-purple-500" />
          </Button>
          {selectedFile ? (
            <div className="flex flex-col items-center justify-center mt-2">
              <p className="text-sm text-gray-700 mb-2">
                Selected file: <span className="font-medium">{selectedFile.name}</span>
                <Button onClick={clearSelectedFile} variant="ghost" size="sm" className="ml-2 p-1">
                  <X className="h-5 w-5 text-gray-700 hover:text-red-500" />
                </Button>
              </p>
              <Button
                onClick={handleUpload}
                className="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-md text-sm"
              >
                Extract Requirements
              </Button>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <p className="text-sm text-gray-500 mb-4">
                Please select a file to proceed for requirement extraction
              </p>
              <Button
                disabled
                className="bg-gray-400 text-white font-semibold py-2 px-4 rounded-md text-sm cursor-not-allowed"
              >
                Extract Requirements
              </Button>
            </div>
          )}
          <p className="text-sm text-gray-500 mt-4">
            Upload only 1 file, maximum file size 5MB, acceptable file types: pdf
          </p>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
      </CardContent>
    </Card>
  );
};