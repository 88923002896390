import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/utils';

interface TooltipProviderProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider> {
  children: React.ReactNode;
}

/**
 * TooltipProvider component
 * @description Wraps the TooltipPrimitive.Provider component
 * @param {TooltipProviderProps} props - The props for the TooltipProvider component
 * @returns {JSX.Element} The TooltipProvider component
 */
const TooltipProvider = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Provider>,
  TooltipProviderProps
>(({ children, ...props }) => {
  return <TooltipPrimitive.Provider {...props}>{children}</TooltipPrimitive.Provider>;
});
TooltipProvider.displayName = 'TooltipProvider';

/**
 * TooltipProps interface
 * @description Extends the props of TooltipPrimitive.Root and adds a children prop
 */
interface TooltipProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> {
  children: React.ReactNode;
}
/**
 * Tooltip component
 * @description Wraps the TooltipPrimitive.Root component and forwards the ref
 * @param {TooltipProps} props - The props for the Tooltip component
 * @param {React.ForwardedRef<React.ElementRef<typeof TooltipPrimitive.Root>>} ref - The forwarded ref
 * @returns {JSX.Element} The Tooltip component
 */
const Tooltip = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Root>, TooltipProps>(
  ({ children, ...props }) => <TooltipPrimitive.Root {...props}>{children}</TooltipPrimitive.Root>,
);
Tooltip.displayName = 'Tooltip';

/**
 * TooltipTriggerProps interface
 * @description Extends the props of TooltipPrimitive.Trigger
 */
interface TooltipTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> {
  children: React.ReactNode;
}

const TooltipTrigger = React.forwardRef<HTMLDivElement, TooltipTriggerProps>(
  ({ children, ...props }) => {
    return <TooltipPrimitive.Trigger {...props}>{children}</TooltipPrimitive.Trigger>;
  },
);
TooltipTrigger.displayName = 'TooltipTrigger';

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  className?: string;
  sideOffset?: number;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = 'TooltipContent';

export { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent };
