import React from 'react';
import { Header } from '@/components/common/Header';
import { UploadSection } from '@/components/upload/UploadRequirements';
import { ProfileInfo } from '@/components/ProfileInfo';
import { DataTable } from '@/components/workflow/WorkFlowTable';
import { Feedback } from '@/components/feedback/Feedback';

export const HomePage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow mx-auto px-4 py-8 w-full max-w-[1400px]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div className="md:col-span-1 h-full">
            <ProfileInfo />
          </div>
          <div className="md:col-span-2 h-full">
            <UploadSection />
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <DataTable />
        </div>
      </main>
      <Feedback />
    </div>
  );
};
