import axios from "axios";
import { FeedbackData } from '@/types';

const API_URL = process.env.REACT_APP_API_URL;

export const uploadDocument = async (file: File, orgInfo: { orgName: string, email: string, projectName: string }): Promise<void> => {
  const formData = new FormData();
  formData.append("pdfFile", file);
  formData.append("orgName", orgInfo.orgName);
  formData.append("email", orgInfo.email);
  formData.append("projectName", orgInfo.projectName);

  console.log(API_URL);
  if(API_URL){
    try {
      const response = await axios.post(`${API_URL}/extract/classify`, formData, {
        responseType: "blob",
      });
  
      const blob = new Blob([response.data], { type: "application/zip" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "output.zip";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error uploading document:", error);
      throw error;
    }
  } else {
    console.log("API_URL is not set");
    throw new Error("API_URL is not set");
  }
};

export const sendFeedback = async (formData: FeedbackData): Promise<void> => {
  if(API_URL){
  try {
    const response = await axios.post(`${API_URL}/slack/send-feedback`, formData, {
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log("Feedback sent successfully");
  } catch (error) {
    console.error("Error sending feedback:", error);
    if (axios.isAxiosError(error)) {
      console.error("Axios error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        headers: error.response?.headers,
      });
    }
    throw error;
  }
}else{
  console.log("API_URL is not set");
  throw new Error("API_URL is not set");
}
};
