import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { store } from '@/redux/store';
import { Footer } from '@/components/common/Footer';
import { Toaster } from '@/components/ui/toaster';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import { HomePage } from '@/pages/HomePage';
import { ClassificationResults } from '@/pages/ClassificationResults';
import { TenderDocuments } from './pages/TenderDocuments';

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <div className="container-wrapper">
            <main className="container-content">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/classification-results/:id" element={<ClassificationResults />} />
                <Route path="/tender-documents/:id" element={<TenderDocuments />} />
              </Routes>
            </main>
            <Footer />
            <Toaster />
          </div>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
