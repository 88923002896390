import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Header } from '@/components/common/Header';
import { Feedback } from '@/components/feedback/Feedback';

interface ClassificationResult {
  id: number;
  pageNumber: number;
  section: string;
  text: string;
  category: 'Technical' | 'Commercial' | 'Safety';
}

const generateDummyData = (): ClassificationResult[] => {
  const data: ClassificationResult[] = [];
  const categories: ('Technical' | 'Commercial' | 'Safety')[] = [
    'Technical',
    'Commercial',
    'Safety',
  ];
  const sections = [
    'Introduction',
    'Requirements',
    'Specifications',
    'Terms and Conditions',
    'Appendix',
  ];

  for (let i = 0; i < 100; i++) {
    data.push({
      id: i + 1,
      pageNumber: Math.floor(Math.random() * 50) + 1,
      section: sections[Math.floor(Math.random() * sections.length)],
      text: `Sample text for item ${i + 1}. This is a placeholder for actual requirement text.`,
      category: categories[Math.floor(Math.random() * categories.length)],
    });
  }

  return data;
};

const dummyData = generateDummyData();

export const ClassificationResults: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState<keyof ClassificationResult>('pageNumber');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [categoryFilter, setCategoryFilter] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredAndSortedData = useMemo(() => {
    return dummyData
      .filter(
        (item) =>
          (categoryFilter === 'all' || item.category === categoryFilter) &&
          Object.values(item).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
          ),
      )
      .sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
      });
  }, [dummyData, searchTerm, sortColumn, sortDirection, categoryFilter]);

  const paginatedData = filteredAndSortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(filteredAndSortedData.length / itemsPerPage);

  const handleSort = (column: keyof ClassificationResult) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleDownloadCSV = () => {
    const headers = ['ID', 'Page Number', 'Section', 'Text', 'Category'];
    const csvContent = [
      headers.join(','),
      ...filteredAndSortedData.map((item) =>
        [
          item.id,
          item.pageNumber,
          item.section,
          `"${item.text.replace(/"/g, '""')}"`,
          item.category,
        ].join(','),
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'classification_results.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto py-8">
        <h1 className="text-2xl font-bold mb-4">Requirement Classification Results</h1>
        <Card>
          <CardContent className="p-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mb-6">
              {' '}
              {/* Improved responsive layout and spacing */}
              <Input
                placeholder="Search..."
                className="max-w-sm w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="flex items-center space-x-2">
                <Select value={categoryFilter} onValueChange={setCategoryFilter}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Filter by category" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All Categories</SelectItem>
                    <SelectItem value="Technical">Technical</SelectItem>
                    <SelectItem value="Commercial">Commercial</SelectItem>
                    <SelectItem value="Safety">Safety</SelectItem>
                  </SelectContent>
                </Select>
                <Button
                  onClick={handleDownloadCSV}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-md text-sm"
                >
                  Download CSV
                </Button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    {['pageNumber', 'section', 'text', 'category'].map((column) => (
                      <TableHead
                        key={column}
                        className="cursor-pointer bg-gray-100 text-purple-600 font-medium"
                        onClick={() => handleSort(column as keyof ClassificationResult)}
                      >
                        {column.charAt(0).toUpperCase() + column.slice(1)}
                        {sortColumn === column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {paginatedData.map((result) => (
                    <TableRow key={result.id}>
                      <TableCell>{result.pageNumber}</TableCell>
                      <TableCell>{result.section}</TableCell>
                      <TableCell>{result.text}</TableCell>
                      <TableCell>{result.category}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className="flex justify-between items-center mt-4">
              <div>
                Showing {(currentPage - 1) * itemsPerPage + 1} to{' '}
                {Math.min(currentPage * itemsPerPage, filteredAndSortedData.length)} of{' '}
                {filteredAndSortedData.length} results
              </div>
              <div className="flex space-x-2">
                <Button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Button>
                <Button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </main>
      <Feedback />
    </div>
  );
};
