import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileInfo } from '@/redux/slices/uploadSlice';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { RootState } from '@/redux/store';

const InputWithStar: React.FC<React.ComponentProps<typeof Input>> = (props) => (
  <div className="relative">
    <Input {...props} />
    <span className="absolute top-1 right-3 text-red-500">*</span>
  </div>
);

export const ProfileInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const currentInfo = useSelector((state: RootState) => state.upload.organizationInfo);

  const [orgName, setOrgName] = useState(currentInfo.orgName);
  const [email, setEmail] = useState(currentInfo.email);
  const [projectName, setProjectName] = useState(currentInfo.projectName);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!orgName || !email || !projectName) {
      toast({
        title: "Missing Information",
        description: "Please fill in all mandatory fields.",
        className: "bg-red-500 text-white",
        duration: 3000,
      });
      return;
    }

    dispatch(setProfileInfo({ orgName, email, projectName }));
    
    toast({
      title: "Profile Saved",
      description: "Your profile information has been successfully saved.",
      className: "bg-green-600 text-white",
      duration: 3000,
    });
  };

  return (
    <Card className="w-full shadow-md bg-white h-full flex flex-col">
      <CardContent className="p-6 flex flex-col flex-grow">
        <h2 className="text-xl font-medium text-center text-gray-700 mb-2">Profile Information</h2>
        <p className="text-sm text-center text-gray-500 mb-4">
          Please fill this before proceeding to upload document
        </p>
        <form onSubmit={handleSubmit} className="space-y-4 flex flex-col flex-grow">
          <div className="space-y-4 flex-grow">
            <InputWithStar
              type="text"
              placeholder="Organization Name"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              className="w-full"
            />
            <InputWithStar
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
            <InputWithStar
              type="text"
              placeholder="Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="flex justify-end mt-auto">
            <Button
              type="submit"
              className="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-md text-sm"
            >
              Save
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};