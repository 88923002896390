import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { MessageSquare, X } from 'lucide-react';
import { useToast } from '@/components/ui/use-toast';
import { sendFeedback } from '@/services/api';
import { FeedbackData } from '@/types';
import { RootState } from '@/redux/store';

export const Feedback: React.FC = () => {
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const organizationInfo = useSelector((state: RootState) => state.upload.organizationInfo);

  const [formData, setFormData] = useState<FeedbackData>({
    name: '',
    organization: '',
    email: '',
    feedback: '',
  });

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      name: '',
      organization: organizationInfo.orgName || '',
      email: organizationInfo.email || '',
    }));
  }, [organizationInfo]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting feedback:', formData);

    try {
      await sendFeedback(formData);
      console.log('Feedback sent to Slack successfully');
      toast({
        title: 'Feedback Submitted Successfully',
        description: 'Thank you for your feedback!',
        className: 'bg-green-600 text-white',
        duration: 3000,
      });
      setIsOpen(false);
      setFormData({
        name: '',
        organization: '',
        email: '',
        feedback: '',
      });
    } catch (error) {
      console.error('Error sending feedback:', error);
      toast({
        title: 'Error',
        description: 'Failed to send feedback. Please try again later.',
        className: 'bg-red-600 text-white',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <Button
        className="fixed bottom-4 right-4 z-50 bg-white text-purple-600 hover:bg-gray-100 border border-gray-300 shadow-sm rounded-full"
        onClick={() => setIsOpen(true)}
      >
        <MessageSquare className="mr-2 h-5 w-5" />
        Feedback
      </Button>
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full relative">
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              onClick={() => setIsOpen(false)}
            >
              <X className="h-6 w-6" />
            </button>
            <h2 className="text-2xl font-bold mb-2">Provide Feedback</h2>
            <p className="text-gray-500 mb-6">
              We value your input. Please share your thoughts with us.
            </p>
            <form onSubmit={handleSubmit} className="space-y-4">
              <Input
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className="w-full border border-gray-300 rounded-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-purple-500"
              />
              <Input
                name="organization"
                placeholder="Organization"
                value={formData.organization}
                onChange={handleInputChange}
                required
                className="w-full border border-gray-300 rounded-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-purple-500"
              />
              <Input
                name="email"
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="w-full border border-gray-300 rounded-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-purple-500"
              />
              <Textarea
                name="feedback"
                placeholder="Your feedback"
                value={formData.feedback}
                onChange={handleInputChange}
                required
                className="w-full border border-gray-300 rounded-sm px-4 py-2 focus:outline-none focus:ring-1 focus:ring-purple-500"
                rows={4}
              />
              <div className="flex justify-center w-full">
                <Button
                  type="submit"
                  className="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-6 rounded-sm w-full"
                >
                  Submit Feedback
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};