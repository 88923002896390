import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '@/components/common/Header';
import { Feedback } from '@/components/feedback/Feedback';
import { Card, CardContent } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Trash, FileIcon, FileSpreadsheet, FileText, FileType } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useToast } from '@/components/ui/use-toast';

interface Document {
  id: number;
  fileName: string;
  uploadDate: string;
  size: string;
  type: 'xlsx' | 'doc' | 'docx' | 'pdf' | 'csv';
}

const dummyDocuments: Document[] = [
  { id: 1, fileName: 'RFP_Document.pdf', uploadDate: '30-05-2024', size: '2.5 MB', type: 'pdf' },
  { id: 2, fileName: 'Requirements.docx', uploadDate: '29-05-2024', size: '1.8 MB', type: 'docx' },
  { id: 3, fileName: 'Budget.xlsx', uploadDate: '28-05-2024', size: '500 KB', type: 'xlsx' },
  { id: 4, fileName: 'Specifications.csv', uploadDate: '27-05-2024', size: '200 KB', type: 'csv' },
];

const getFileIcon = (type: Document['type']) => {
  switch (type) {
    case 'xlsx':
      return <FileSpreadsheet className="h-6 w-6 text-green-500" />;
    case 'doc':
    case 'docx':
      return <FileText className="h-6 w-6 text-blue-500" />;
    case 'pdf':
      return <FileType className="h-6 w-6 text-red-500" />;
    case 'csv':
      return <FileSpreadsheet className="h-6 w-6 text-green-500" />;
    default:
      return <FileIcon className="h-6 w-6 text-gray-500" />;
  }
};

export const TenderDocuments: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [documents, setDocuments] = useState<Document[]>(dummyDocuments);
  const { toast } = useToast();

  const handleDelete = (docId: number) => {
    setDocuments(documents.filter((doc) => doc.id !== docId));
  };

  const handleAddFile = () => {
    toast({
      title: 'Feature in development',
      description: 'This feature will be available soon.',
      className: 'bg-purple-600 text-white',
    });
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto py-8">
        <h1 className="text-2xl font-bold mb-4">Tender Documents of Project {id}</h1>
        <Card>
          <CardContent className="p-6">
            <div className="mb-4 flex items-center">
              <Button
                onClick={handleAddFile}
                className="bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-md text-sm"
              >
                Add file
              </Button>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-1/5">File Name</TableHead>
                  <TableHead className="w-1/5">Upload Date</TableHead>
                  <TableHead className="w-1/5">Size</TableHead>
                  <TableHead className="w-1/5">Type</TableHead>
                  <TableHead className="w-1/5">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {documents.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell className="font-medium">
                      <div className="flex items-center">
                        {getFileIcon(doc.type)}
                        <span className="ml-2">{doc.fileName}</span>
                      </div>
                    </TableCell>
                    <TableCell>{doc.uploadDate}</TableCell>
                    <TableCell>{doc.size}</TableCell>
                    <TableCell>{doc.type.toUpperCase()}</TableCell>
                    <TableCell>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDelete(doc.id)}
                              aria-label="Delete Document"
                            >
                              <Trash className="h-4 w-4 text-red-500" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Delete this file</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </main>
      <Feedback />
    </div>
  );
};
