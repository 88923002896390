import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileInfo {
  orgName: string;
  email: string;
  projectName: string;
}

interface UploadState {
  organizationInfo: ProfileInfo;
}

const initialState: UploadState = {
  organizationInfo: {
    orgName: '',
    email: '',
    projectName: '',
  },
};

const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    setProfileInfo: (state, action: PayloadAction<ProfileInfo>) => {
      state.organizationInfo = action.payload;
    },
  },
});

export const { setProfileInfo } = uploadSlice.actions;
export default uploadSlice.reducer;
