import React from 'react';
import { Link } from 'react-router-dom';
import { LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';

export const Header: React.FC = () => {
  return (
    <header className="flex justify-between items-center p-4 bg-white shadow-md">
      <Link to="/" className="flex items-center">
        <img src="/booma-logo.svg" alt="Booma Logo" className="h-10 mr-3" />
        <span className="text-xl font-semibold text-custom-blue">Booma AI</span>
      </Link>
      <Button
        variant="outline"
        size="lg"
        className="text-purple-600 border-purple-600 hover:bg-purple-100"
      >
        <LogOut className="mr-2 h-5 w-5" />
        Logout
      </Button>
    </header>
  );
};
