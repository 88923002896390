import React from 'react';
import { Card, CardContent } from '@/components/ui/card';

export const Footer: React.FC = () => {
  return (
    <footer>
      <Card className="mt-8 shadow-md">
        <CardContent className="py-4 text-center text-gray-600">
          <p>&copy; 2024 Booma AI. All rights reserved.</p>
          <div className="mt-2">
            <a href="https://booma.ai" className="text-purple-600 hover:underline mx-2">
              Privacy Policy
            </a>
            <a href="https://booma.ai" className="text-purple-600 hover:underline mx-2">
              Terms of Service
            </a>
            <a href="https://booma.ai" className="text-purple-600 hover:underline mx-2">
              Contact Us
            </a>
          </div>
        </CardContent>
      </Card>
    </footer>
  );
};
